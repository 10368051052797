// 底部3cookies
<template>
  <div class="main">
    <div class="top">
      <div class="top-top">
        <div class="top-liney"></div>
        <div class="top-title">Cookie Policy</div>
      </div>
      <div class="top-text">
        Our website uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        browse our website and also allows us to improve our site. By continuing
        to browse the site, you are agreeing to our use of cookies.
      </div>
      <div class="top-text">
        A cookie is a small file of letters and numbers that we store on your
        browser or the hard drive of your computer if you agree. Cookies contain
        information that is transferred to your computer’s hard drive.
      </div>
    </div>
    <div class="content">
      <div class="content-title">We use the following cookies:</div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Strictly necessary cookies.</span>
          These are cookies that are required for the operation of our
          website.They include, for example, cookies that enable you to log into
          secure areas of our website, use a shopping cart or make use of
          e-billing services.
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Analytical/performance cookies.</span>
          They allow us to recognise and count the number of visitors and to see
          how visitors move around our website when they are using it. We use
          Google Analytics to collect data on user behaviour, demographics (age,
          gender) and interests. This enables us to evaluate and improve our
          website content, marketing and services for our clients. This site
          usage data and your IP address will be sent to and stored by Google on
          servers in the United States. Where required to do so by law, or where
          such third parties process the information on Google’s behalf, Google
          may also share this information with third parties. This information
          is anonymous, and Google undertakes not to associate your IP address
          with any other data held by Google.
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Functionality cookies.</span>
          These are used to recognise you when you return to our website. This
          enables us to personalise our content for you, greet you by name and
          remember your preferences (for example, your choice of language or
          region).
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Targeting cookies.</span>
          These cookies record your visit to our website, the pages you have
          visited and the links you have followed. We will use this information
          to make our website and the advertising displayed on it more relevant
          to your interests. We may also share this information with third
          parties for this purpose.
        </div>
      </div>
    </div>
    <div class="fot">
      <div class="fot-text">
        The Google Analytics features we implement may related to Display
        Advertising (e.g. Remarketing, Google Display Network Impression
        Reporting, the DoubleClick Campaign Manager integration, or Google
        Analytics Demographics and Interest Reporting). To opt out of Google
        Analytics for Display Advertising or amend your preferences for Google
        Display Network ads please visit Ads Settings . To stop Google Analytics
        collecting any data from you when you visit a website that has it
        incorporated, please visit Google Analytics’ currently available
        opt-outs for the web to update these settings.
      </div>
      <div class="fot-text">
        Other third parties (including, for example, advertising networks and
        providers of external services like web traffic analysis services) may
        also use cookies, over which we have no control. These cookies are
        likely to be analytical/performance cookies or targeting cookies.
      </div>
      <div class="fot-text">
        ou can block all cookies by activating the setting on your browser that
        allows you to refuse the setting of all or some cookies. However, if you
        use your browser settings to block all cookies (including essential
        cookies) you may not be able to access all or parts of our site.
      </div>
      <div class="fot-text">
        Except for essential cookies, all cookies will expire after 2 years.
      </div>
    </div>
  </div>
</template>
<script>
import { usVisitLogInsert } from "@/api/home.js";
export default {
  name: "Cookies",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
    };
  },
  activated() {
    // console.log("activated");
    this.addVisitLogInsert();
  },
  methods: {
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "Cookies";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1110px;
  margin: 51px auto;
}
.top-liney {
  width: 1px;
  height: 60px;
  background-color: #293744;
}

.top-top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 38px;
}
.top-title {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin-left: 16px;
}
.top-text {
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 17px;
}
.content {
  width: 1110px;
  margin: 0 auto 51px;
}

.content-title {
  font-size: 20px;
  font-weight: 500;
  color: #262626;
  margin-bottom: 16px;
}
.content-text {
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 10px;
  display: flex;
}
.font-w {
  font-weight: bold;
}
.s-point {
  width: 8px !important;
  height: 8px;
  background-color: #293744;
  border-radius: 50%;
  margin: 7px 13px;
}
.content-text-r {
  width: calc(100% - 35px);
}
.fot {
  width: 1110px;
  margin: 0 auto 51px;
}
.fot-text {
  font-size: 15px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 10px;
}
</style>
